<template>
  <transition name="modal">
    <div class="modal-mask">
      <div class="modal-wrapper">
        <div class="modal-container">
          <div class="modal-body">
            <slot name="body">
              <h4 class="location">
                목적지
              </h4>
              <span class="modal-text">
                목적지에 도착했습니다.<br>
                다음 목적지로 이동할까요?
              </span>
            </slot>
          </div>

          <v-row class="modal-footer ma-0 pa-0">
            <v-col
              class="btn-cancel pa-0"
              cols="6"
              type="button"
              @click="$emit('close')"
            >
              태스크 취소
            </v-col>
            <v-col
              class="btn-action pa-0"
              cols="6"
              type="button"
              @click="$emit('action')"
            >
              다음으로
            </v-col>
          </v-row>
        </div>
      </div>
    </div>
  </transition>
</template>

<script>
  export default {
    name: 'TaskModal',
    props: {
      location: {
        type: String,
        default: null
      },
    }
  }
</script>

<style lang="scss" scoped>
.modal-mask {
  position: fixed;
  z-index: 9998;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, .5);
  display: table;
  transition: opacity .3s ease;
  word-break: keep-all;
}

.modal-wrapper {
  display: table-cell;
  width: 100%;
  height: 100%;
  vertical-align: middle;
}

.modal-container {
  width: 50%;
  height: 50%;
  margin: 0px auto;
  background-color: #393939;
  border-radius: 10px;
  transition: all .3s ease;
}

.modal-body {
  width: 100%;
  height: 35vh;
  padding: 5vh 0 0;
  font-family: sans-serif;
  font-style: normal;
  font-weight: 500;
  font-size: 3vw;
  text-align: center;
  color: #ffffff;
}

.location {
  color: #41bfb4;
  font-size: 3.5vw;
}

.modal-text {
  margin: 50% 0 0;
}

.btn-cancel {
  text-align: center;
  background-color: #c4c4c4;
  font-family: sans-serif;
  font-style: normal;
  font-weight: 500;
  font-size: 3vw;
}

.btn-action {
  text-align: center;
  background-color: #7c7c7c;
  color: #ffffff;
  font-family: sans-serif;
  font-style: normal;
  font-weight: 500;
  font-size: 3vw;
}

/*
 * The following styles are auto-applied to elements with
 * transition="modal" when their visibility is toggled
 * by Vue.js.
 *
 * You can easily play with the modal transition by editing
 * these styles.
 */

.modal-enter {
  opacity: 0;
}

.modal-leave-active {
  opacity: 0;
}

.modal-enter .modal-container,
.modal-leave-active .modal-container {
  -webkit-transform: scale(1.1);
  transform: scale(1.1);
}

</style>
