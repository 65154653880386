<template>
  <v-container class="pa-0">
    <default-modal
      v-if="showModal"
      :message="modalState.message"
      :action="modalState.action"
      :cancel-message="modalState.cancelMessage"
      @close="closeModal"
      @action="doAction"
    />
    <task-modal
      v-if="showTaskModal"
      @close="showTaskModal = false"
      @action="nextTask"
    />
    <task-edit-modal
      v-if="showTaskEditModal"
      :job-repeat="jobRepeat"
      @close="showTaskEditModal = false"
      @action="(data) => changeTask(data)"
    />
    <v-row
      class="header"
      justify="space-around"
    >
      <img
        src="@/assets/back.png"
        alt="뒤로가기"
        type="button"
        @click="$router.replace('/config')"
      >
      <span class="span-back"> &nbsp;&nbsp;뒤로 </span>
      <v-spacer />
      <v-col
        class="header-img ma-0 pa-0"
        align-self="center"
        cols="auto"
      >
        <img
          src="@/assets/add.png"
          type="button"
          alt="추가 버튼"
          @click="$router.replace('/task/work')"
        >
      </v-col>
      <span class="span-task-add">&nbsp;&nbsp;태스크 추가&nbsp;</span>
    </v-row>
    <v-col class="content-container mt-10">
      <v-col
        class="doing-task"
        cols="12"
      >
        <v-row>
          <v-col cols="12">
            <h1 class="title-task">
              진행중 태스크
            </h1>
          </v-col>
          <template v-if="jobs.length">
            <template v-if="!$store.state.isTaskStop">
              <v-col class="pa-0">
                <v-card
                  class="task"
                  color="#d4d4d4"
                >
                  <v-row justify="space-between">
                    <v-col>
                      <span class="ml-5">
                        {{ workName }}
                      </span>
                    </v-col>
                    <v-spacer />
                    <v-col>
                      <v-btn
                        class="btn-task"
                        height="70px"
                        color="#00BFB4"
                        dark
                        @click="clickTaskPauseBtn"
                      >
                        정지
                      </v-btn>
                    </v-col>
                  </v-row>
                </v-card>
              </v-col>
            </template>
            <template v-else>
              <v-col class="pa-0">
                <v-card
                  class="task"
                  color="#d4d4d4"
                >
                  <v-row justify="space-between">
                    <v-col>
                      <span class="ml-5">
                        {{ workName }}
                      </span>
                    </v-col>
                    <v-col>
                      <v-btn
                        class="btn-task"
                        height="70px"
                        color="#00BFB4"
                        dark
                        @click="clickTaskPauseBtn"
                      >
                        재개
                      </v-btn>
                    </v-col>
                    <v-col>
                      <v-btn
                        class="btn-task"
                        height="70px"
                        color="#7c7c7c"
                        dark
                        @click="cancelTask"
                      >
                        1회 취소
                      </v-btn>
                    </v-col>
                  </v-row>
                </v-card>
              </v-col>
            </template>
          </template>
          <template v-else>
            <v-col class="pa-0">
              <p class="empty">
                진행중인 태스크가 없습니다.
              </p>
            </v-col>
          </template>
        </v-row>
        <template v-if="jobs.length">
          <v-row>
            <v-col cols="12 mt-12">
              <h1 class="title-task">
                잔여 태스크
              </h1>
            </v-col>
            <v-col
              v-for="(item, i) in jobs"
              :key="i"
              class="btn-container mt-7 pa-0"
              cols="12"
            >
              <v-card
                class="task"
                color="#d4d4d4"
              >
                <v-row
                  class="task-row"
                  justify="space-between"
                >
                  <v-col cols="4">
                    <span class="ml-5">
                      {{ item.Works[0].work_name }}
                    </span>
                  </v-col>
                  <v-col cols="3">
                    <span class="task-num-color">{{ item.job_order }}</span>
                    <span> 순위</span>
                  </v-col>
                  <v-col cols="2">
                    <span class="task-num-color">
                      {{ item.job_repeat - (item.job_done_count + item.job_fail_count) }}
                    </span>
                    <span> 회</span>
                  </v-col>
                  <v-col cols="3">
                    <v-btn
                      class="btn-task"
                      height="70px"
                      color="#7c7c7c"
                      dark
                      @click="editTask(i)"
                    >
                      수정
                    </v-btn>
                  </v-col>
                </v-row>
              </v-card>
            </v-col>
          </v-row>
        </template>
      </v-col>
    </v-col>
  </v-container>
</template>

<script>
import DefaultModal from '@/components/modals/defaultModal.vue';
import TaskModal from '@/components/modals/task/taskModal.vue';
import TaskEditModal from '@/components/modals/task/taskEditModal.vue';
import EventBus from '@/main.js';

export default {
  name: 'Job',
  components: {
    DefaultModal,
    TaskModal,
    TaskEditModal,
  },
  props: {
    locations: {
      type: Array,
      default: null
    },
    mapInfo: {
      type: Object,
      default: null
    }
  },
  data() {
    return {
      showModal: false,
      showTaskModal: false,
      showTaskEditModal: false,
      modalState: {
        message: null,
        action: null,
        cancelMessage: null,
      },
      jobs: [],
      jobId: null,
      jobRepeat: null,
      workName: null,
    }
  },
  created() {
    EventBus.$on('showTaskName', data => {
      this.workName = data;
    });
    this.initialize();
  },
  beforeDestroy() {
    EventBus.$off('showTaskName');
    EventBus.$off('job:work_finish/job');
    EventBus.$off('job:update/job');
    EventBus.$off('all_stop/job');
    EventBus.$off('job:goto_location_id/job');
  },
  methods: {
    initialize() {
      EventBus.$off('job:work_finish/job');
      EventBus.$off('job:update/job');
      EventBus.$off('all_stop/job');
      EventBus.$off('job:goto_location_id/job');

      EventBus.$on('job:work_finish/job', ()=> {
        this.fetchJob().catch(err=> {throw Error(err)});
      });
      EventBus.$on('job:update/job', ()=> {
        this.fetchJob().catch(err=> {throw Error(err)});
      });

      EventBus.$on('all_stop/job', ()=> {
        if(!this.$store.state.isTaskStop) this.$store.commit('stopTask');
      });

      EventBus.$on('job:goto_location_id/job', (data)=> {
        const workId = data.split("/")[1];
        this.$axios.get(`/work/list/${workId}`)
          .then(res=> {
            if(res.status === 200) this.workName = res.data[0].work_name;
          })
          .catch(err=> {throw Error(err)});
      });

      return this.fetchJob().catch(err=> Error(err));
    },
    fetchJob() {
      return this.$axios.get('/job')
        .then(res => {
          if (res.status === 200) {
            this.jobs = res.data;
            if (this.jobs.length === 0) {
              this.$store.commit('reStartTask');
            }
            EventBus.$emit('socketEmit', 'job:goto_location_id');
          }
        });
    },
    clickTaskPauseBtn() {
      this.$store.state.isTaskStop ? this.$store.commit('reStartTask') : this.$store.commit('stopTask');
      if (this.$store.state.isTaskStop) {
        return this.fetchPause();
      } else {
        this.fetchResume();
      }
    },
    fetchPause() {
      return this.$axios.get('/job/pause')
        .catch(err => { throw Error(err); });
    },
    fetchResume() {
      return this.$axios.get('/job/resume')
        .catch(err => { throw Error(err); });
    },
    editTask(index) {
      const job = this.jobs[index]

      this.jobId = job.job_id;
      this.jobRepeat = job.job_repeat - (job.job_done_count + job.job_fail_count);
      this.showTaskEditModal = true;
    },
    changeTask(data) {
      if (data.isChecked) {
        this.deleteTask();
        this.showTaskEditModal = false;
      } else {
        if (data.number > 0) {
          this.updateTask(data.number);
          this.showTaskEditModal = false;
        } else {
          this.$toast('0보다 큰 수를 입력해 주세요', {
            position: 'top-center'
          });
        }
      }
    },
    deleteTask() {
      const id = this.jobId;
      return this.$axios.delete(`/job/${id}`, {
        job_id: id
      })
        .then(this.fetchJob)
        .then(() => {
          EventBus.$emit('clearTaskInfo');
        })
        .catch(err => { throw Error(err); });
    },
    updateTask(number) {
      number = parseInt(number);
      const id = this.jobId;

      return this.$axios.put(`/job/update/${id}`, {
        job_id: id,
        job_repeat: number
      })
        .then(this.fetchJob)
        .then(()=> {
          EventBus.$emit('socketEmit', 'changeJob');
        })
        .catch(err => { throw Error(err); });
    },
    nextTask() {
      window.alert('다음 태스크');
      this.showTaskModal = false;
    },
    cancelTask() {
      this.modalState.message = '진행중인 태스크를 취소하시겠습니까?';
      this.modalState.action = '태스크 취소';
      this.modalState.cancelMessage = '아니오';
      this.showModal = true;
    },
    closeModal() {
      this.modalState.message = null;
      this.modalState.action = null;
      this.modalState.cancelMessage = null;
      this.showModal = false;
    },
    doAction() {
      if (this.modalState.action === '태스크 취소') {
        this.showModal = false;
        this.$store.commit('reStartTask');
        return this.fetchStop()
          .then(this.fetchJob)
          .then(() => {
            EventBus.$emit('clearTaskInfo');
            EventBus.$emit('socketEmit', 'changeJob');
          })
          .catch(err => { throw Error(err); });
      }
      this.closeModal();
    },
    fetchStop() {
      return this.$axios.put('/job/stop').catch(err => { throw Error(err); });
    },
  }
}
</script>

<style lang="scss" scoped>


.span-back {
  color: #ffffff;
  font-size: 80px !important;
}

.span-task-add  {
  justify-self: right;
  color: #ffffff;
  font-size: 80px !important;
}

.content-container {
  height: 80vh;
  overflow: auto;
}

.title-task {
  color: #ffffff;
  font-size: 50px;
}

.task {
  border-radius: 15px !important;
  vertical-align: middle;
  font-size: 50px;
  color: #7c7c7c !important;
}

.task-row {
  height: inherit;
}

.btn-task {
  width: 80%;
  border-radius: 10px;
  font-size: 40px !important;
  font-weight: bold !important;
}
.empty {
  color: #ffffff;
  font-size: 20px;
  text-align: center !important;
}

@media (max-width: 1700px) {
  .header img {
    width: 50px;
    height: 50px;
  }

  .span-back {
    font-size: 40px !important;
  }

  .span-task-add {
    font-size: 40px !important;
  }

  .task {
    height: 50px;
    font-size: 30px;
  }

  .task > .row > .col {
    padding: 0 !important;
  }

  .btn-task {
    height: 40px !important;
    font-size: 30px !important;
  }

  .task-num-color {
    color: #00BFB4;
  }
}


@media (max-width: 1140px) {
  .header img {
    width: 30px;
    height: 30px;
  }

  .span-back {
    font-size: 20px !important;
  }

  .span-task-add {
    font-size: 20px !important;
  }
    .title-task {
    font-size: 30px;
  }

  .task {
    height: 35px;
    font-size: 20px;
  }

  .btn-task {
    height: 25px !important;
    font-size: 20px !important;
  }

  .empty {
    color: #ffffff;
    font-size: 12px;
    text-align: center !important;
  }
}

</style>
